.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .spin-logo-md {
    animation: spin-md infinite 15s linear;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .spin-logo {
    animation: spin infinite 15s linear;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .bell:hover {
    animation: bell-shake 1 2s cubic-bezier(.36,.07,.19,.97) both;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes spin {
  from {
    transform: translate(-50%) rotate(0deg);
  }
  to {
    transform: translate(-50%) rotate(360deg);
  }
}

@keyframes spin-md {
  from {
    transform: translate(-50%,-50%) rotate(0deg);
  }
  to {
    transform: translate(-50%,-50%) rotate(360deg);
  }
}

@keyframes bell-shake {
  0% { transform: rotate(0); }
  15% { transform: rotate(5deg); }
  30% { transform: rotate(-5deg); }
  45% { transform: rotate(4deg); }
  60% { transform: rotate(-4deg); }
  75% { transform: rotate(2deg); }
  85% { transform: rotate(-2deg); }
  92% { transform: rotate(1deg); }
  100% { transform: rotate(0); }
}

@keyframes ripple {
  to {
    opacity: 0;
    transform: scale(2);
  }
}


.bubble-group {
  background: radial-gradient(circle at top left, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0) 50%),
  radial-gradient(circle at bottom right, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0) 70%),
  linear-gradient(145deg, rgba(255, 255, 255, 0.9), rgba(240, 240, 240, 0.6));
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), inset 0 1px 2px rgba(255, 255, 255, 0.4);
  border-radius: 9999px;
  padding: 1rem;
}

.bubble-group div {
  transform:scale(1);
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out; 
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}